<template>
  <section class="container outContainer">
    <div class="search">
      <el-date-picker
        class="each"
        v-model="searchInfo.param.createTime"
        type="date"
        placeholder="选择日期">
      </el-date-picker>
    </div>
    <div class="tableContainer">
      <el-table :data="tableList" height="100%">
        <el-table-column align="center" label="场所" prop="unitName"></el-table-column>
        <el-table-column align="center" label="耗费电量(度)" prop="electricQuantity"></el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTimeStr"></el-table-column>
      </el-table>
    </div>
  </section>
</template>

<script>
import { timeChange } from '@/api/publicFun.js';
export default {
  data() {
    return {
      tableList:[ // 表格数据
        {
          unitName:'光阳国际',
          electricQuantity:200,
          createTimeStr:'2024-02-22'
        },
        {
          unitName:'天鼎公寓',
          electricQuantity:200,
          createTimeStr:'2024-02-22'
        },
        {
          unitName:'浜河海棠景轩',
          electricQuantity:200,
          createTimeStr:'2024-02-22'
        },
      ],
      searchInfo:{ // 搜索条件
        pageNo:1,
        pageSize:10,
        param:{
          createTime:null,
        },
      },
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    init(){
      // this.tableList.forEach(element => {
      //   element.createTimeStr = timeChange(element.createTime,'day');
      // });
    },
  },
};
</script>
<style scoped lang="scss">
  .container{
    padding: 20px;
    box-sizing: border-box;
  }
</style>
